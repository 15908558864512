import React from 'react';
import './App.css';
import {MagneticElement} from "./components/magnetic/MagneticElement.component";
// @ts-ignore
import sniffSound from './audio/sniff.mp3';
// @ts-ignore
import eatingSound from './audio/eating.mp3';

function App() {
    return (
        <div style={{
            display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"
        }}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <div className="oemer">
                    <MagneticElement text="Ömer" icon="rocket.png" sound={sniffSound}/>
                </div>
                <div className="falk">
                    <MagneticElement text="Falk" icon="sausage2.png" sound={eatingSound}/>
                </div>
            </div>
            <span style={{color: "aqua", fontFamily: "Courier New"}}>
                Der eine ist dick und der andere schreit.
            </span>
        </div>
    );
}

export default App;

import './magneticElement.scss';
import icon from '../../img/face_icon.svg';
import React, {useEffect, useRef} from "react";

export type MagneticElementType = {
    text?: string;
    icon?: string;
    sound?: string;
}
export const MagneticElement = (props: MagneticElementType) => {
    const audio = new Audio(props.sound);

    const elementRef = useRef(null);
    const backgroundRef = useRef(null);
    const textRef = useRef(null);
    const iconRef = useRef(null);

    const startAnimation = () => {
        const transition = `all 150ms ease`;
        if (elementRef.current !== null) {
            // @ts-ignore
            elementRef.current.style.transition = transition;
        }
        if (backgroundRef.current !== null) {
            // @ts-ignore
            backgroundRef.current.style.transition = transition;
        }
        if (textRef.current !== null) {
            // @ts-ignore
            textRef.current.style.transition = transition;
        }
    }
    const endAnimation = () => {
        setTimeout(() => {
            if (elementRef.current !== null) {
                // @ts-ignore
                elementRef.current.style.transition = '';
            }
            if (backgroundRef.current !== null) {
                // @ts-ignore
                backgroundRef.current.style.transition = '';
            }
            if (textRef.current !== null) {
                // @ts-ignore
                textRef.current.style.transition = '';
            }
        }, 150);
    }
    const move = (event: any) => {
        const x = event.nativeEvent.layerX - event.target.clientWidth / 2;
        const y = event.nativeEvent.layerY - event.target.clientHeight / 2;
        if (elementRef.current !== null) {
            // @ts-ignore
            elementRef.current.style.transform = `translate(${x / 4}px, ${y / 4}px)`;
        }
        if (backgroundRef.current !== null) {
            // @ts-ignore
            backgroundRef.current.style.transform = `translate(${x / 6}px, ${y / 6}px)`;
        }
        if (textRef.current !== null) {
            // @ts-ignore
            textRef.current.style.transform = `translate(${x / 20}px, ${y / 20}px)`;
        }
    };
    const start = (event: any) => {
        startAnimation();
        endAnimation();

    };
    const end = (event: any) => {
        startAnimation();
        if (elementRef.current !== null) {
            // @ts-ignore
            elementRef.current.style.transform = `translate(0px, 0px)`;
        }
        if (backgroundRef.current !== null) {
            // @ts-ignore
            backgroundRef.current.style.transform = `translate(0px, 0px)`;
        }
        if (textRef.current !== null) {
            // @ts-ignore
            textRef.current.style.transform = `translate(0px, 0px)`;
        }
        endAnimation();
    };

    return (
        <div>
            <button className="magnetic-container" onClick={() => audio.play()} onMouseMove={move} onMouseEnter={start}
                    onMouseLeave={end}>
                <div className="magnetic-element" ref={elementRef}>
                    <img ref={iconRef} src={icon} alt="sry"></img>
                </div>
                <div className="magnetic-background" ref={backgroundRef}>
                    <span className="magnetic-text" ref={textRef}
                          style={{color: "black", padding: "3px", fontFamily: "Courier New"}}>{props.text}</span>
                </div>
            </button>
        </div>
    );
}
